<template>
  <div>
    <InputNumber v-model="modelValue" :disabled="disabled" :max="max" :min="min" />
  </div>
</template>

<script>
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel"],

  components: {
    //"i-input-number": InputNumber,
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue)
      },
    },
    max() {
      return this.inputParams.max != null ? this.inputParams.max : Infinity;
    },
    min() {
      return this.inputParams.min != null ? this.inputParams.min : -Infinity;
    },
  },
  mounted() {
    if (this.inputParams) { }
  },
};
export function initItem(input) {
  input.max = null;
  input.min = null;
  return input;
}
</script>
