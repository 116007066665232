import axios from "axios";
import { wrap } from "./common";

export const initFrame = ({ frameId }) =>
  axios.post("/preset/init/frame", {
    frameId,
  });

export const lookupFrame = ({ query, sort, options }) =>
  axios.post("/api/frame/userFrame", {
    query,
    sort,
    options,
  });
export const userFrameRecent = ({ options }) =>
  axios.post("/api/frame/userFrameRecent", {
    options,
  });

export const userSubjectPolicy = (subjectId, action) =>
  axios.get(`/frame/userSubjectPolicy/${subjectId}/${action}`);

export const createContainer = (data, frameId) =>
  axios.post("/preset/create/container", { ...data, frameId });

export const createCardList = (data, frameId) =>
  axios.post("/preset/create/container", { ...data, frameId });

export const subNodeContainer = (data) =>
  axios.post("/preset/subNode/container", data);

export const createInvite = (data) =>
  axios.post("/preset/create/invite/base", data);

export const createBoard = (variety, data) =>
  axios.post(`/preset/create/board/${variety}`, data);

export const createFirmCard = (data) =>
  axios.post("/preset/create/card/firm", data);
export const createRelationSelf = (data) =>
  axios.post("/preset/create/relation/self", data);

export const moveCard = (data) => axios.post("/preset/move/card/firm", data);
export const updateContainer = (data) =>
  axios.post("/preset/update/container", data);

export const updateDynamicCard = (data) =>
  axios.post("/preset/update/card/dynamic", data);

export const subNodeBoardDept = ({ position, options }) =>
  axios.post("/preset/subNode/container", {
    position,
    type: "dept",
    options,
  });

export const lookupDept = (query, expand, paging) =>
  axios.post("/preset/lookup/dept", {
    query,
    expand,
    paging,
  });
export const lookupUser = ({ query, options }) =>
  axios.post("/preset/lookup/user", {
    query,
    options,
  });

export const deleteNode = (id) =>
  axios.post("/preset/delete/container", {
    id,
  });
export const deleteEdge = (id, position) =>
  axios.post("/preset/edge/delete/level", {
    id,
    position,
  });

export const deleteBoardUser = (id) =>
  axios.post("/preset/delete/relation", {
    id,
  });
export const updateBoardUser = (id, payload) =>
  axios.post("/preset/update/relation", {
    id,
    payload,
  });

function convertIdentifier(ident) {
  if (typeof ident === "string") {
    return {
      id: ident,
    };
  }
  return ident;
}

export const dataPool = {
  inner: {
    create: (ident, mixdata) =>
      wrap(
        axios.post("/preset/create/datapool/inner", {
          ...convertIdentifier(ident),
          mixdata,
        })
      ),
    lookup: (ident, query = {}, options = {}) =>
      wrap(
        axios.post("/preset/lookup/datapool/inner", {
          ...convertIdentifier(ident),
          query,
          options,
        })
      ),
    update: (ident, mixdata) =>
      wrap(
        axios.post("/preset/create/datapool/inner", {
          ...convertIdentifier(ident),
          mixdata,
        })
      ),
    del: (ident, filter = {}) =>
      wrap(
        axios.post("/preset/delete/datapool/inner", {
          ...convertIdentifier(ident),
          filter,
        })
      ),
  },
  collection: {
    copy: ({ source, frameId }) =>
      wrap(
        axios.post("/preset/copy/datapool/collection", {
          source,
          frameId,
        })
      ),
    create: (ident, doc) =>
      wrap(
        axios.post("/preset/create/datapool/collection", {
          ...convertIdentifier(ident),
          doc,
        })
      ),

    lookup: ({ ident, query = {}, paging }) =>
      wrap(
        axios.post("/preset/lookup/datapool/collection", {
          ...convertIdentifier(ident),
          query,
          paging,
        })
      ),
    findOneAndUpdate: (ident, filter, update, options) =>
      wrap(
        axios.post("/preset/findOneAndUpdate/datapool/collection", {
          ...convertIdentifier(ident),
          update,
          filter,
          options,
        })
      ),
    update: (ident, update) =>
      wrap(
        axios.post("/preset/update/datapool/collection", {
          ...convertIdentifier(ident),
          update,
        })
      ),
    updateMany: (ident, filter, update) =>
      wrap(
        axios.post("/preset/updateMany/datapool/collection", {
          ...convertIdentifier(ident),
          filter,
          update,
        })
      ),
    del: (ident, filter = {}) =>
      wrap(
        axios.post("/preset/delete/datapool/collection", {
          ...convertIdentifier(ident),
          filter,
        })
      ),
  },
};

export const createCardUser = (data) =>
  axios.post("/preset/create/relation/card", data);

export const lookupRelation = (query, expand, paging) =>
  axios.post("/preset/lookup/relation", { query, expand, paging });

export const deleteRelation = (id) =>
  axios.post("/preset/delete/relation", { id });

export const listCardDept = (frameId, cardId, maxUserNumber = 10) =>
  axios.post("/preset/list/dept/cardDept", {
    frameId,
    cardId,
    maxUserNumber,
  });
export const createCard = ({ variety, formModel, frameId, preId }) =>
  axios.post(`/preset/create/card/${variety}`, {
    ...formModel,
    frameId,
    preId,
  });

export const initPerson = (variety, data, frameId) =>
  axios.post("/preset/init/person/", { ...data, frameId });

export const setScore = (data) =>
  axios.post("/preset/setscore/card/class", data);

export const containerType = {
  lookup: (query = {}, options = {}) =>
    wrap(
      axios.post("/preset/lookup/containerType", {
        query,
        options,
      })
    ),
};
export const applyFile = ({ provider, file, uuid }) =>
  axios.post("/preset/upload/file/apply", { provider, file, uuid });

export const downloadFile = ({ fileId, provider }) =>
  axios.post("/preset/download/file", { fileId, provider });

export const viewContainer = ({ id, frameId }) =>
  axios.post("/preset/view/container", { id, frameId });

/** **** Dynamic ****** */
export const createDynamicContainer = (modelData) =>
  axios.post("/preset/create/card/dynamic", { ...modelData });

export const subNodeDynamicContainer = ({
  position,
  filter = {},
  query = { index: "index1", variety: "dynamic" },
  downlevel = 1,
}) =>
  axios.post("/preset/subNode/container/dynamic", {
    position,
    filter,
    query,
    downlevel,
  });

export const initEdgeDept = ({
  objectId,
  objectFrameId,
  subjectId,
  subjectFrameId,
}) =>
  axios.post("/preset/initEdge/dept", {
    objectId,
    objectFrameId,
    subjectId,
    subjectFrameId,
  });
export const newEdge = (edgeParams) =>
  axios.post("/preset/initEdge/node", edgeParams);

export const exportDatapool = ({ frameId }) =>
  axios.post("/preset/export/datapool", {
    frameId,
  });

export const importDatapool = ({ frameId, dataPools }) =>
  axios.post("/preset/import/datapool", {
    frameId,
    dataPools,
  });

export const userSelfFrame = () =>
  axios.post("/preset/user/selfFrame", {
    // frameId, dataPools
  });

export const excelExport = (params) =>
  axios.get("/preset/container/excel/export", { params });

export const createVariety = (params) =>
  axios.post("/preset/create/variety", params);

export const lookupVariety = (params) =>
  axios.post("/preset/lookup/variety", params);
export const updateVariety = (filter, update) =>
  axios.post("/preset/update/variety", { filter, update });
export const deleteVariety = (filter) =>
  axios.post("/preset/delete/variety", filter);

export const lookupVarietyPolicy = (userId) =>
  axios.post("/preset/frame/userFramePayload", { userId });

export const updateVarietyPolicy = (filter, update) =>
  axios.post("/preset/update/userFramePayload", { filter, update });

export const policyView = (objectId, frameId) =>
  axios.post("/preset/policy/view", { objectId, frameId });

export const policyCreate = (data) =>
  axios.post("/preset/create/policy/origin", data);

export const policyDelete = (data) =>
  axios.post("/preset/delete/policy/origin", data);

export const deleteBoard = ({ frameId }) =>
  axios.post("/preset/delete/frame", { frameId });

export const exportNode = ({ position, depth }) =>
  axios.post("/preset/export/node", { position, depth });

export const importNode = ({ position, nodes }) =>
  axios.post("/preset/import/node", { position, nodes });

export const getNodeById = ({ id, frameId }) =>
  axios.post("/preset/getNodeById/container", { id, frameId });

export const getNodeByOd = ({ id, frameId }) =>
  axios.post("/preset/getNodeByOd/container", { coordinate, frameId });

export const lookupDebug = ({ query, paging }) =>
  axios.post("/preset/lookup/debug", { query, paging });

export const updateBoard = (id, params) =>
  axios.post("/api/frame/update/" + id, params);

export const baiduTalk = (params) => axios.post("/preset/baidu/talk", params);

export const inviteFrameUser = (params) =>
  axios.post("/preset/invite/frame/user", params);

export const lookupTemplate = (params) =>
  axios.post("/preset/lookup/template", params);
