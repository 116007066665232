<template>
  <div class="d-flex">
    <div v-if="inputParams?.pushable">
      <!-- <Button @click="addItem()" size="small" class="re-small add-btn" type="primary" v-if="!selector_scene">
        增加
      </Button> -->
      <div style="display: flex">
        <Button @click="openModel" size="small" class="re-small add-btn"
          style="margin-top: 3px;margin-right: 18px;">选择</Button>
        <!-- <p style="margin-left: 16px" v-html="forigonText"></p> -->
        <Modal v-model="modalShow" :title="'选择' + inputParams.label" width="760" top="60" draggable sticky scrollable
          :mask="false" @on-ok="assignProperty">
          <PlotRender :scene="inputParams.selector_scene" :params="{ _curNode: getNode(inputParams.selector_key) }" />
        </Modal>
      </div>
    </div>
    <div style="flex: 1;padding: 0px 0px;">
      <div v-for="(item, i) in nodeArr" :key="i" class="d-flex edge-item">
        <EdgeItem :index="i" v-if="item" :key="i">
          <PlotRender :scene="inputParams.selectItem_scene" :key="item.vid"
            :params="{ _curNode: item, inEdgeForm: true }" />
          <slot></slot>
        </EdgeItem>
        <div class="delete-btn" v-if="inputParams?.pushable">
          <Button @click="handleRemove(i)" size="small" class="re-small " ghost type="error">
            删除
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useNodeStore } from '@/stores/node';
import { computed, provide, reactive } from 'vue';
import EdgeItem from './EdgeItem';
export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel"],
  provide: function () {
    return {
      formModel: computed(() => this.modelValue) //this.modelValue
    };
  },
  components: { EdgeItem },
  data() {
    return {
      tempArr: [],
      nodeArr: [],
      modalShow: false,
    };
  },
  methods: {

    handleRemove(index) {
      console.log("this.modelValue", this.modelValue, index,)
      // const deletedNode = this.tempArr[index]
      this.tempArr.splice(index, 1);
      this.nodeArr.splice(index, 1);

      this.modelValue = this.tempArr
      console.log("this.modelValue2", this.modelValue, index)

      // const deletedVid = deletedNode[this.inputParams.params]

      // this.nodeStore.removeBranch(this.inputParams?.selector_branch, deletedVid)

      // this.modelValue = this.modelValue
    },
    openModel() {
      this.modalShow = true
      //this.nodeStore.initBranch(this.inputParams?.selector_branch, this.nodeArr)
    },
    getNode(key) {
      // const node = this.nodeStore.getNode({ preIds: [this.nodeStore.frame._id], where: { module: { key } } })
      const node = this.nodeStore.getEdgeNodeByKey({ key })
      console.log('getNode', key, node,)
      return node
    },
    assignProperty() {
      // this.curCard = this.selectedRow;
      let branch = this.nodeStore.getBranch({
        branchId: this.inputParams?.selector_branch,
      })
      console.log('branch', branch)

      let newEdge = branch.nodes.map(n => {
        return {
          name: this.inputParams.variety,
          [this.inputParams.params]: n.vid
        }
      })
      if (!this.formModel.edge) {
        this.formModel.edge = []
      }
      console.log('this.formModel1', this.formModel)


      this.tempArr = newEdge;
      this.modelValue = newEdge;
      this.nodeArr = branch.nodes;
      console.log('this.formModel3', this.formModel)
    },
  },
  computed: {
    modelValue: {
      get: function () {
        // if (this.inputParams.varietyType == 'edge') {

        const list = this.formModel?.edge?.filter(n => n.name === this.inputParams.variety)
        return reactive(list ? list : [])
        //  }

      },
      set: function (newValue) {
        //this.formModel[this.inputParams.index] = newValue;
        //  this.formModel.edge = newValue;
        // let otherEdges = this.formModel.edge.filter(n => n.name !== this.inputParams.variety || n.value.name !== this.inputParams.variety)
        // console.log("this.formModel0", otherEdges, newValue)
        // let newEdges = [...otherEdges, ...newValue]
        let formModel = this.formModel
        //  this.formModel.edge.push(...newValue);
        //  this.formModel.edge = newEdges


        for (let i = formModel.edge.length - 1; i >= 0; i--) {
          let curEdge = formModel.edge[i]
          if (curEdge.name === this.inputParams.variety) {
            formModel.edge.splice(i, 1)
          }
        }

        console.log("this.formModeledge", formModel.edge)
        if (!this.formModel.test) {
          console.log("!this.formModel.test")
          this.formModel.test = []
        }
        this.formModel.test.push(...newValue)
        this.formModel.edge.push(...newValue)


        //this.formModel.edge = [...this.formModel.edge, ...newValue]
        //this.formModel.edge.push(...newValue)
        console.log("this.formModelset", this.formModel.edge, newValue)
        // this.formModel[this.inputParams.index] = newValue;
      },
    },
  },
  watch: {
    modelValue(newValue) {
      provide('formModel1', newValue);
      provide('formModel2', newValue);

    },
  },
  mounted() {
    this.tempArr = this.modelValue

    this.nodeArr = this.modelValue.map(n => {
      const vid = n[this.inputParams.params]
      const node = this.nodeStore.nodes.find(v => v.vid == vid)
      if (!node) {
        return node
      }
      return node
    })

    // if (!this.inputParams?.edgeCount) {
    //   this.modelValue.fill({})
    // }
  },
  setup() {
    const nodeStore = useNodeStore()

    return {
      nodeStore,
    }
  }
};
</script>
<style lang="less" scoped>
.edge-item {
  .delete-btn {
    visibility: hidden;
  }
}

.edge-item:hover {
  .delete-btn {
    visibility: visible
  }
}
</style>