// Pinia Store
import {
  deleteNode,
  getNodeById,
  getNodeByOd,
  subNodeContainer,
} from "@/api/frame";
import { subNode } from "@/api/graph";
import axios from "axios";
import * as _ from "lodash";
import { defineStore } from "pinia";
export const useNodeStore = defineStore("node", {
  // 转换为函数
  state: () => ({
    /*
    [{
            "vid": "66409785584d3856d40ee800",
            "tags": [
                {
                    "name": "frame",
                    "props": {
                        "varietyName": 0,
                        "name": "test7",
                        "variety": "dev",
                        "createdAt": 1715509161,
                        "info": ""
                    }
                }
            ]
             "edge": [
              {
                  "src": "66409785584d3856d40ee800",
                  "dst": "66409d8a914fa81bcc62f603",
                  "type": 1,
                  "name": "edge_root_module",
                  "ranking": 0,
                  "props": {
                      "coordinate": "3"
                  }
              }
            ]
    }]
    
    */
    edges: [],
    nodes: [],
    relations: [],
    pivotnodes: [],
    frame: {},
    branch: {},
    temporary: [],
  }),
  getters: {
    getNodes: (state) => {
      return ({ preIds = [], where = {} }) =>
        state.nodes.filter((node) => {
          // 检查 edge 中的 src 是否属于 preIds 数组
          const srcInPreIds = node.edge.some((edge) =>
            preIds.includes(edge.src)
          );
          // 检查 tag 是否满足指定结构
          const tagMatches = _.isMatch(node.tag, where);
          return srcInPreIds && tagMatches;
        });
    },
    getNodeById: (state) => (id) => {
      return state.nodes.find((n) => n.vid === id);
    },
    getEdgeNodeByKey: (state) => {
      return ({ key }) => {
        const edge = state.edges.find((edge) => edge.props?.key === key);
        return state.nodes.find((node) => node.vid === edge?.dst);
      };
    },
    getNode: (state) => {
      return ({ preIds = [], where = {} }) =>
        state.nodes.find((node) => {
          // 检查 edge 中的 src 是否属于 preIds 数组
          // _.some(node.edge, { 'user': 'barney', 'active': false });

          const srcInPreIds = node.edge.some((edge) =>
            preIds.includes(edge.src)
          );
          // 检查 tag 是否满足指定结构
          const tagMatches = _.isMatch(node.tag, where);

          return srcInPreIds && tagMatches;
        });
    },
    getBranch: (state) => {
      return ({ branchId, where, depth = 1 }) => {
        const nodes = state.branch?.[branchId]?.nodes.filter((n) => {
          if (!n?.vid) {
            return false;
          }
          for (const key in where) {
            if (n.tag[key] !== where[key]) {
              return false;
            }
          }

          return true;
        });
        return {
          nodes,
          paging: state.branch?.[branchId]?.paging,
        };
      };
    },
    getUserNode: (state) => (user_id) => {
      return state.nodes.find((n) => {
        if (n?.tag?.user?.user_id === user_id) {
          return true;
        }
      });
    },
  },
  actions: {
    async subNode({
      frameId,
      preIds,
      M,
      N,
      edgeTypeList = ["relation"],
      whereConditions,
      orderBy,
      limitOffset = 0,
      limitNumber = 20,
      branchIds = { default: [] },
    }) {
      if (!preIds || preIds.includes("")) {
        throw Error("未指定上级节点");
      }

      const res = await subNode({
        frameId,
        preIds,
        M,
        N,
        edgeTypeList,
        whereConditions,
        orderBy,
        limitOffset,
        limitNumber,
        // branchIds,
      });
      const ways = res?.data?.ways;
      // console.log("subNode ways", ways);

      // this.initNode(res?.data);
      // return ways?.map((way) => {
      //   // this.insertNode(way?.src);
      //   // this.insertNode(way?.dst);
      //   // this.insertNode(way?.edge);
      //   //  console.log("branchIds[way?.index]", way?.index, branchIds[way?.index]);
      //   return this.initWay(way, branchIds[way?.index]);
      // });
      return this.initWays(ways, branchIds);
    },
    initEdge(edges) {
      edges?.map((edge) => {
        if (!edge) return;
        const oldIndex = this.edges.findIndex(
          (e2) =>
            e2.dst === edge.dst && e2.src === edge.src && e2.name === edge.name
        );

        // if (edge.name === "coordinate") {
        //   edge.coordinate = edge.props?.od?.split("_").map((n) => parseInt(n));
        // }
        // let src = this.nodes.find((n) => {
        //   return n.vid === edge.src;
        // });
        // let dst = this.nodes.find((n) => {
        //   return n.vid === edge.dst;
        // });

        if (oldIndex > -1) {
          this.edges[oldIndex] = edge;
        } else {
          this.edges.push(edge);
        }
      });
    },
    initEdge2(edges, nodes) {
      edges?.map((subEdges) => {
        if (!Array.isArray(subEdges)) {
          subEdges = [subEdges];
        }
        subEdges?.map((edge) => {
          if (edge.type == -1) {
            const temp = edge.src;
            edge.src = edge.dst;
            edge.dst = temp;
            edge.type = 1;
          }
          for (let key in edge?.props) {
            if (edge.props[key] === 0) {
              edge.props[key] = null;
            }
          }

          const oldIndex = this.edges.findIndex(
            (e2) =>
              e2.dst === edge.dst &&
              e2.src === edge.src &&
              e2.name === edge.name
          );
          if (oldIndex > -1) {
            this.edges[oldIndex] = edge;
            Object.assign(this.edges[oldIndex], edge);
            //this.edges[oldIndex] = edge;
          } else {
            this.edges.push(edge);
          }

          const dstNode = nodes.find((n) => edge.dst == n.vid);
          const srcNode = nodes.find((n) => edge.src == n.vid);

          if (dstNode && !dstNode.edge) {
            dstNode.edge = [];
          }
          const e2Index = dstNode?.edge.findIndex(
            (e2) =>
              e2.dst === edge.dst &&
              e2.src === edge.src &&
              e2.name === edge.name
          );
          if (e2Index == -1) {
            dstNode.edge.push(edge);
          }

          if (srcNode && !srcNode.reEdge) {
            srcNode.reEdge = [];
          }
          const e3Index = srcNode?.reEdge.findIndex(
            (e2) =>
              e2.dst === edge.dst &&
              e2.src === edge.src &&
              e2.name === edge.name
          );
          if (e3Index == -1) {
            srcNode.reEdge.push(edge);
          }
        });
      });
    },
    initInEdge(edges) {
      return edges?.map((edge) => {
        const node = this.nodes.find((v) => v.vid === edge.dst);
        console.log("initInEdge1", edge, node);

        const oldIndex = node?.edge.findIndex((e) => {
          return (
            e.src === edge.src && e.dst === edge.dst && e.name === edge.name
          );
        });
        if (oldIndex > -1) {
          node.edge[oldIndex] = edge;
        } else {
          node.edge.push(edge);
        }
        return node;
      });
    },
    initWays(ways, branchIds, insert, allInBranch) {
      return ways?.map((way) => {
        return this.initWay2(
          way,
          branchIds?.[way?.index],
          insert,

          allInBranch
        );
      });
    },
    initWay(way, branchs, insert = false, reversely = false) {
      //let src = way?.src;
      const paging = way?.paging;

      let vertexs = reversely ? way?.src : way?.dst;
      //let vertexs = way?.dst;
      const edges = way?.eg;
      const deEdges = way?.de;
      let extend = way?.extend;
      this.initEdge(edges);
      this.deleteEdge(deEdges);

      // const initVertex = (node) => {
      //   const vid = node.vid;
      //   //查询已有节点
      //   let oldNode = this.nodes.find((n) => n.vid === node.vid);
      //   //新边
      //   let newEdges = edges?.filter((n) => {
      //     return n?.dst == node.vid;
      //   });
      //   node.ext = {};
      //   //新扩展
      //   let newExtEdge = extend?.eg?.filter((n) => {
      //     return n?.src == node.vid;
      //   });

      //   const newTags = node.tags.map((tag) => {
      //     const newProps = {};
      //     for (const key in tag.props) {
      //       if (tag.props.hasOwnProperty(key) && tag.props[key] !== 0) {
      //         newProps[key] = tag.props[key];
      //       }
      //     }
      //     return { name: tag.name, props: newProps };
      //   });
      //   // console.log("newTags", newTags);

      //   if (oldNode) {
      //     //属性覆盖
      //     newTags.map((tag) => {
      //       oldNode.tag[tag.name] = tag.props;
      //     });
      //     // console.log("oldNode2", oldNode);

      //     if (!oldNode.edge) {
      //       oldNode.edge = [];
      //     }
      //     newEdges?.map((edge) => {
      //       const indexE = oldNode?.edge?.findIndex(
      //         (e2) =>
      //           e2.dst === edge.dst &&
      //           e2.src === edge.src &&
      //           e2.name === edge.name
      //       );
      //       if (indexE > -1) {
      //         oldNode.edge[indexE] = edge;
      //       } else {
      //         oldNode?.edge?.push(edge);
      //       }
      //     });
      //     return oldNode;
      //   } else {
      //     const payload = {};
      //     newTags.map((tag) => {
      //       payload[tag.name] = tag.props;
      //     });
      //     const newNode = { vid: node.vid, tag: payload, edge: newEdges };

      //     this.nodes.push(newNode);
      //     return newNode;
      //   }
      // };
      // src?.map(initVertex);
      let nodes = vertexs?.map((v) => this.initVertex(v, edges));
      console.log("reversely", reversely);
      if (!vertexs) {
        console.log("vertexs", vertexs);
        nodes = this.initInEdge(edges);
        console.log("nodes", nodes);
      }
      this.initExt(extend, nodes);

      //let extendNodes = extend?.map(initVertex);
      //console.log("initVertex nodes", nodes);
      if (branchs) {
        branchs.map((id) => {
          if (!insert) {
            // 覆盖支线
            this.initBranch(id, nodes, paging);

            // this.branch[id].nodes = nodes;
            // this.branch[id].paging = paging; // 覆盖支线
          } else {
            console.log("id", id);
            console.log("this.branch", this.branch[id]);
            this.insertBranch(id, nodes);
            // if (!this.branch[id]) {
            //   this.branch[id] = { nodes: [] };
            // }

            // this.branch[id].nodes = this.branch[id]?.nodes.concat(nodes); // 插入支线
          }
        });
      }
      return nodes;
    },
    initWay2(way, branchs, insert = false, allInBranch = false) {
      if (!way) {
        return;
      }
      //let src = way?.src;
      const paging = way?.paging;

      const edges = way?.eg;
      const deEdges = way?.de;
      let extend = way?.extend;

      let srcNodes = way?.src?.map((v) => this.initVertex2(v));
      let dstNodes = way?.dst?.map((v) => this.initVertex2(v));

      if (srcNodes) {
        this.initEdge2(edges, [...srcNodes]);
      }
      if (dstNodes) {
        this.initEdge2(edges, [...dstNodes]);
      }
      //  this.initEdge2(edges, [...srcNodes, ...dstNodes]);
      this.deleteEdge(deEdges);

      // if (!vertexs) {
      //   console.log("vertexs", vertexs);
      //   nodes = this.initInEdge(edges);
      //   console.log("nodes", nodes);
      // }
      //this.initExt(extend, dstNodes);
      this.initWay2(extend);

      //console.log("initVertex nodes", dstNodes);
      if (branchs) {
        branchs.map((id) => {
          if (!insert) {
            // 覆盖支线
            this.initBranch(id, dstNodes, paging);
            if (allInBranch) {
              console.log("allInBranch", srcNodes);
              this.insertBranch(id, srcNodes);
            }
            // this.branch[id].nodes = nodes;
            // this.branch[id].paging = paging; // 覆盖支线
          } else {
            console.log("id", id);
            console.log("this.branch", this.branch[id]);
            this.insertBranch(id, dstNodes);
            if (allInBranch) {
              console.log("allInBranch2", srcNodes);

              this.insertBranch(id, srcNodes);
            }
            // if (!this.branch[id]) {
            //   this.branch[id] = { nodes: [] };
            // }

            // this.branch[id].nodes = this.branch[id]?.nodes.concat(nodes); // 插入支线
          }
        });
      }
      return dstNodes;
    },
    insertBranch(id, nodes) {
      console.log("id, nodes", id, nodes);
      if (!Array.isArray(nodes)) {
        nodes = [nodes];
      }

      if (!this.branch[id]) {
        this.branch[id] = { nodes: [], paging: {} };
      }
      console.log("this.branch[id]", this.branch[id]);

      nodes.map((item) => {
        let index = this.branch[id]?.nodes.findIndex(
          (n) => n?.vid === item?.vid
        );
        if (index === -1) {
          this.branch[id]?.nodes.push(item);
        }
      });

      // this.branch[id]?.nodes.forEach((node) => {
      //   let index = nodes?.findIndex((n) => n?.vid === node?.vid);
      //   console.log("insertBranch2", index);
      //   if (index !== -1) {
      //     nodes.splice(index, 1);
      //   }
      // });
      // // for (let i = this.branch[id]?.nodes.length; i >= 0; i++) {
      // //   const item = this.branch[id]?.nodes[i]
      // //   let index = nodes?.findIndex((n) => n?.vid === item?.vid);
      // //   if (index !== -1) {
      // //     nodes.splice(index, 1);
      // //   }
      // // }
      // console.log("insertBranch2", nodes, this.branch[id]?.nodes);
      // this.branch[id].nodes = this.branch[id]?.nodes.concat(nodes); // 插入支线
    },
    initBranch(id, nodes, paging) {
      this.branch[id] = {
        nodes,
        paging,
      };
      console.log("this.branch", this.branch[id]);
    },
    removeBranch(id, vid) {
      const deleteIndex = this.branch[id].nodes.findIndex((n) => n.vid === vid);
      console.log("deletedVid", deleteIndex);

      this.branch[id].nodes.splice(deleteIndex, 1);
    },
    //插入总线,点转节点
    initVertex(vertex, edges) {
      //  const vid = node.vid;
      //查询已有节点
      let oldNode = this.nodes.find((n) => n.vid === vertex.vid);
      //新边
      let newEdges = edges?.filter((n) => {
        return n?.dst == vertex.vid;
      });
      let newReEdges = edges?.filter((n) => {
        return n?.src == vertex.vid;
      });

      const newTags = vertex.tags.map((tag) => {
        const newProps = {};
        for (const key in tag.props) {
          if (tag.props.hasOwnProperty(key) && tag.props[key] !== 0) {
            newProps[key] = tag.props[key];
          }
        }
        return { name: tag.name, props: newProps };
      });

      if (oldNode) {
        //属性覆盖
        newTags.map((tag) => {
          oldNode.tag[tag.name] = tag.props;
        });
        // console.log("oldNode2", oldNode);

        if (!oldNode.edge) {
          oldNode.edge = [];
        }
        newEdges?.map((edge) => {
          const indexE = oldNode?.edge?.findIndex(
            (e2) =>
              e2.dst === edge.dst &&
              e2.src === edge.src &&
              e2.name === edge.name
          );
          if (indexE > -1) {
            oldNode.edge[indexE] = edge;
          } else {
            oldNode?.edge?.push(edge);
          }
        });
        newReEdges?.map((edge) => {
          const indexE = oldNode?.reEdge?.findIndex(
            (e2) =>
              e2.dst === edge.dst &&
              e2.src === edge.src &&
              e2.name === edge.name
          );
          if (indexE > -1) {
            oldNode.reEdge[indexE] = edge;
          } else {
            oldNode?.reEdge?.push(edge);
          }
        });
        return oldNode;
      } else {
        const tags = {};
        newTags.map((tag) => {
          tags[tag.name] = tag.props;
        });
        const newNode = {
          vid: vertex.vid,
          tag: tags,
          edge: newEdges,
          reEdge: newReEdges,
        };

        this.nodes.push(newNode);
        return newNode;
      }
    },
    initVertex2(vertex) {
      //查询已有节点
      let oldNode = this.nodes.find((n) => n?.vid === vertex?.vid);
      const newTags = vertex?.tags?.map((tag) => {
        const newProps = {};
        for (const key in tag.props) {
          if (tag.props.hasOwnProperty(key) && tag.props[key] !== 0) {
            newProps[key] = tag.props[key];
          }
        }
        return { name: tag.name, props: newProps };
      });

      if (oldNode) {
        //属性覆盖
        newTags?.map((tag) => {
          oldNode.tag[tag.name] = tag.props;
        });
        return oldNode;
      } else {
        const tags = {};
        newTags?.map((tag) => {
          tags[tag.name] = tag.props;
        });
        let newNode;
        if (vertex?.vid) {
          newNode = {
            vid: vertex.vid,
            tag: tags,
            edge: [],
            reEdge: [],
          };
          this.nodes.push(newNode);
        }

        //console.log("newNode", newNode);
        return newNode;
      }
    },
    initExt(way, preNodes) {
      console.log("initExt", way);
      if (!way) return;
      const { dst, src, eg } = way;
      console.log("way", way);
      dst?.map((vertex) => {
        const node = this.initVertex(vertex, eg);
        console.log("vertex", node);

        const subEg = eg.filter((e) => {
          e.src == node.vid;
        });
        console.log("subEg", subEg);

        subEg.map((e) => {
          const edge_type = e.name;
          if (!node.extend) {
            node.extend = {};
          }
          let subNodes = node.extend[edge_type];
          if (!subNodes) {
            node.extend[edge_type] = [];
          }
          const subNode = dst.find((v) => v.vid === e.dst);
          subNodes.push(subNode);
          console.log("subNode", subNode);
        });
        const preEgs = eg.filter((e) => {
          return e.dst == node.vid;
        });
        console.log("preEgs", preEgs);

        preEgs.map((preEg) => {
          const edge_type = preEg.name;

          const preNode = preNodes.find((n) => n.vid == preEg.src);
          if (!preNode.extend) {
            preNode.extend = {};
          }
          let subNodes = preNode.extend[edge_type];
          if (!subNodes) {
            preNode.extend[edge_type] = [node];
          } else {
            let hasIndex = preNode.extend[edge_type].findIndex(
              (n) => n.vid === node.vid
            );
            if (hasIndex === -1) {
              preNode.extend[edge_type].push(node);
            } else {
              Object.assign(preNode.extend[edge_type][hasIndex].tag, node.tag);
            }
          }

          //  preNode.ext = node;
          console.log("preNode", preNode);
        });

        // return vertex;
      });
    },
    insertExtend(node, subNoes) {
      console.log("insertExtend", node, subNoes);
      subNoes.map((n) => {
        let preEdges = n?.edge?.filter((e) => {
          return e.src === node.vid;
        });
        console.log("preEdges", preEdges);

        preEdges.map((e) => {
          if (!node?.extend) {
            node.extend = {};
          }
          if (!node.extend?.[e.name]) {
            node.extend[e.name] = [];
          }
          console.log("node?.extend", node?.extend);

          node?.extend?.[e.name].push(n);
          console.log("node?.extend", n);
        });
        // n?.edge?.src == node.vid;
      });

      // extNodes.map((extNode) => {
      //   if (node.extend.findIndex((n) => n.vid == extNode.vid) == -1) {
      //     node.extend.push(extNode);
      //   }
      // });
    },

    //插入或更新 节点队列
    /* initNode(nodes) {
      console.log("initNode--1", nodes);
      if (!Array.isArray(nodes)) {
        nodes = [nodes];
      }
      for (const card of nodes) {
        if (typeof card !== "object" || card === null) {
          continue;
        }
        const index = this.nodes.findIndex((v) => {
          if (card?.node?._id === v.node._id) {
            return true;
          }
        });
        let branchIndexs = [];
        const nodesCard = this.nodes[index];
        console.log("card?.branchs", nodesCard, nodesCard?.branchs);
        nodesCard?.branchs?.forEach((branch) => {
          console.log("branch", branch);
          const bIndex = this.branch[branch].findIndex((branchCard) => {
            return branchCard?.node._id == card?.node?._id;
          });
          branchIndexs.push(bIndex);

          const timeOld = new Date(this.branch[branch][bIndex].node.updatedAt);
          const timeNew = new Date(card.node.updatedAt);

          if (timeNew > timeOld) {
            Object.assign(this.branch[branch][bIndex].node, card.node);
          }
          console.log(
            "branchCard",
            card.node,
            this.branch[branch][bIndex].node
          );
        });

        if (index === -1) {
          this.nodes.push(card);
        } else {
          let timeOld = new Date(this.nodes[index].node.updatedAt);
          let timeNew = new Date(card.node.updatedAt);

          if (timeNew > timeOld) {
            Object.assign(this.nodes[index].node, card.node);
          }
        }
      }
    }, */
    //拉取节点队列
    async initNodes({
      position: { frameId, coordinate },
      filter,
      query,
      sort,
      depth = 1,
      single = false,
      paging,
      index,
      branchId,
    }) {
      const res = await subNodeContainer({
        position: {
          frameId,
          coordinate,
        },
        paging,
        filter,
        query,
        sort,
        depth,
        single,
      });

      if (branchId) {
        this.branch.push({
          id: "",
          initParams: {
            position: { frameId, coordinate },
            filter,
            query,
            sort,
            depth,
            single,
            paging,
            index,
          },
          list: [],
        });
      }
      //单个节点
      if (single) {
        this.initNode([res?.data?.data]);
      } else {
        if (res?.data?.nodes.length === 1) {
          return res?.data?.nodes[0];
        } else {
          let indexNodes = {};
          res?.data?.nodes.map((v) => {
            this.initNode(v.records);
            //获取标识队列
            if (v.index == index) {
              indexNodes = v;
            }
          });
          return indexNodes;
        }
      }
    },
    async getNodeByOd({ coordinate, frameId }) {
      const res = await getNodeByOd({
        coordinate,
        frameId,
      });
      this.initNode([res.data.data]);
    },
    async updateCard({ action, vid, tags, edges, scene }) {
      const res = await axios.post(action, {
        vid: vid,
        tags: tags,
        edges: edges,
        frameId: this.frame._id,
        scene,
        returnDoc: true,
      });
      console.log("res.data", res.data);
      if (res.data?.ways) {
        // card.node.updatedAt = new Date()
        // card.node = res.data.data;
        // this.initNode([card]);
        //  this.initNode(res.data?.ways);
        res.data.ways?.map((way) => {
          // console.log("branchIds[way?.index]", way?.index, branchIds[way?.index]);
          return this.initWay(way);
        });
      }
      return res.data;
      //更新其它节点

      // res?.data?.nodes.map((v) => {
      //   this.initNode(v.records);
      // });
    },

    async initNodeById({ id, frameId }) {
      const res = await getNodeById({
        id,
        frameId,
      });
      if (res?.data?.data) {
        this.initNode([res.data.data]);

        return res?.data?.data;
      }
    },
    async deleteNode(id) {
      //后端删除
      const re = await deleteNode(id);
      const index = this.nodes.findIndex((n) => {
        return id == n.node._id;
      });
      const card = this.nodes[index];
      //支线删除
      card.branchs;

      for (const branchId of card.branchs) {
        const index = this.branch[branchId].findIndex((n) => {
          return id == n.node._id;
        });
        this.branch[branchId].splice(index, 1);

        console.log(branchId);
      }
      //总线删除
      this.nodes.splice(index, 1);

      return re;
    },
    async deleteEdge(edges) {
      console.log("deleteEdge", edges);

      if (!edges || !Array.isArray(edges)) {
        return;
      }
      //后端删除
      edges.map((edge) => {
        const index = this.edges.findIndex((e) => {
          return (
            edge.src === e.src && edge.dst === e.dst && edge.name === e.name
          );
        });
        console.log("deleteEdge", index);
        this.edges.splice(index, 1);
        //入边删除
        const eNode = this.nodes.find((node) => {
          return node.vid === edge.dst;
        });

        const nodeEdgeIndex = eNode.edge.findIndex((e) => {
          return (
            edge.src === e.src && edge.dst === e.dst && edge.name === e.name
          );
        });
        console.log("nodeEdgeIndex", nodeEdgeIndex);

        eNode.edge.splice(nodeEdgeIndex, 1);
        if (eNode.edge.length === 0) {
          eNode.vid = undefined;
        }
        //出边删除
        const reNode = this.nodes.find((node) => {
          return node.vid === edge.src;
        });

        const nodeEdgeIndex2 = reNode.reEdge.findIndex((e) => {
          return (
            edge.src === e.src && edge.dst === e.dst && edge.name === e.name
          );
        });
        console.log("nodeEdgeIndex", nodeEdgeIndex);

        reNode.reEdge.splice(nodeEdgeIndex, 1);
        if (reNode.edge.length === 0) {
          reNode.vid = undefined;
        }
      });
    },

    //重制branch
    // initBranch(id, nodes) {
    //   // console.log("initBranch", id);

    //   if (!this.branch[id]) {
    //     this.branch[id] = nodes;
    //   }
    //   // let curBranch = this.branch[id];
    //   let newBranch = [];
    //   let src = nodes?.src;
    //   let dst = nodes?.dst;
    //   let edges = nodes?.edge;
    //   const initVertex = (node) => {
    //     let newEdge = edges.find((n) => {
    //       return n?.dst == node.vid && n.name == "coordinate";
    //     });
    //     if (!node?.branchs) {
    //       node.branchs = new Set();
    //       node.branchs.add(id);
    //     }
    //     const payload = {};

    //     node.tags.map((tag) => {
    //       const newProps = {};
    //       for (const key in tag.props) {
    //         if (tag.props.hasOwnProperty(key) && tag.props[key] !== 0) {
    //           newProps[key] = tag.props[key];
    //         }
    //       }
    //       payload[tag.name] = newProps;
    //     });
    //     // const index = curBranch.findIndex((n) => n.vid == node.vid);
    //     // if (index > -1) {
    //     //   curBranch[index] = { vid: node.vid, payload, edge: newEdge };
    //     // }else{

    //     // }
    //     newBranch.push({ vid: node.vid, payload, edge: newEdge });
    //   };
    //   dst?.map(initVertex);
    //   this.branch[id] = newBranch;
    // },

    addTemporary(node) {
      const index = this.temporary.findIndex((n) => {
        return n.node._id === node.node._id;
      });
      console.log("index", index);
      if (index >= 0) {
        this.temporary[index] = node;
      } else {
        this.temporary.push(node);
      }
      return true;
    },
  },
});
