<template>
  <div :id="id"></div>
</template>
<script>
import { useNodeStore } from '@/stores/node';
import { Chart } from '@antv/g2';
import _ from "lodash-es";

export default {
  props: {
    options: Object,
    id: String,
    branch: String,
    //  params: { type: Object },
    tagFormat: { type: Object },
    format: Object,
    mountedWatch: Object,
    code: String

  },
  // inject: {
  //   curNode: 'curNode',
  // },
  computed: {
    branchNodes() {
      const nodes = this.nodeStore.getBranch({
        branchId: this.branch,
      })
      console.log('branchNodes0', nodes)
      if (this.code) {
        // this.evalCode(this.code, { Chart, chart: this.chart, _, branchNodes: nodes })
      }
      return nodes
    },
    formatedNodes() {
      if (this.code) {
        return []
      }
      const nodes = this.nodeStore.getBranch({
        branchId: this.branch,
      })
      if (!nodes) {
        return []
      }

      const formatObject = (node, format) => {
        let formatedNode = {}
        for (let key in format) {
          const type = typeof format[key]
          const formatItem = format[key]

          if (type === 'string') {
            formatedNode[key] = _.get(node, formatItem)

          } else if (type === 'object') {
            let value = _.get(node, formatItem.path)
            if (formatItem.type === 'time') {
              const arr = []
              if (value?.year) {
                arr.push(value.year)
              }
              if (value?.month) {
                arr.push(value.month - 1)
              }
              if (value?.day) {
                arr.push(value.day)
              }
              if (value?.hour) {
                arr.push(value.hour)
              }
              if (value?.minute) {
                arr.push(value.minute)
              }
              if (value?.sec) {
                arr.push(value.sec)
              }
              if (value?.microsec) {
                arr.push(value.microsec)
              }
              formatedNode[key] = new Date(...arr)
            } else if (formatItem.type === 'static') {
              formatedNode[key] = formatItem.value

            } else {
              formatedNode[key] = value
            }
          }
        }
        return formatedNode
      }
      const formatDeal = (node, format) => {
        if (Array.isArray(format)) {
          return format.map(subFormat => {
            return formatObject(node, subFormat)
          })
        } else {
          return formatObject(node, format)
        }
      }

      const formatedNodes = []
      // const format = Object.assign({}, this.format)

      for (let i = 0; i < nodes.nodes?.length; i++) {
        const node = nodes.nodes[i]
        const formated = formatDeal(node, this.format)
        if (Array.isArray(formated)) {
          formatedNodes.push(...formated)
        } else {
          formatedNodes.push(formated)
        }
      }
      // return [{ test: 1 }]//formatedNodes//reactive(formatedNodes)
      return formatedNodes //reactive(formatedNodes)
    }
  },
  data() {
    return {
      chart: {},
      chartData: {},
      unwatch: undefined,
      loading: false
    };
  },
  mounted() {
    // 初始化图表实例
    const chart = new Chart({
      container: this.id,
      // width: '100%'
      // options: this.options,
    });
    this.chart = chart
    // console.log('this.options', this.options)

    if (this.code) {
      let t = this.branchNodes
      console.log('mounted')
      this.evalCode(this.code, { Chart, chart: this.chart, _, branchNodes: this.branchNodes })
    } else {
      chart.options(this.options);
      chart.render();
    }
  },
  watch: {
    formatedNodes(newVal) {
      if (this.code) {
        return
      }
      if (newVal.length == 0) {
        return
      }
      let options
      if (this.options?.children) {

        this.options.children.map(child => {
          child.data = newVal
        })
        options = this.options
      } else {
        options = Object.assign({}, this.options, { data: newVal })
      }

      const chart = new Chart({
        container: this.id,
      });
      chart.options(options);
      chart.render();
    },
    mountedWatch: {
      deep: true,
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          for (let i = 0; i < newVal.length; i++) {
            if (!_.isEqual(newVal[i], oldVal?.[i])) {
              this.fetchContext(1, 0);
              break
            }
          }
        } else {
          if (!_.isEqual(newVal, oldVal)) {
            this.fetchContext(1, 0);
          }
        }
      }
    },
  },
  methods: {
    async fetchContext(current = 1, step = 0) {
      try {
        this.loading = true;
        this.$emit("mounted", {
        });

      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;
      }
    },
    evalCode(code, context) {
      // const regex = /^`(.*)`$/;
      //  const match = code.match(regex);
      const restrictedEval = new Function("$", code);
      //  console.log("this.code2", this.code)

      return restrictedEval(context);

    }
  },

  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less"></style>
