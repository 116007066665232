<template>
  <div>
    <!-- <i-input
      v-model="formModel()[inputParams.propertyId]"
      :show-word-limit="inputParams.maxLength > 0"
      :maxlength="inputParams.maxLength > 0 ? inputParams.maxLength : null"
      :placeholder="inputParams.placeholder"
      :disabled="disabled"
      :type="inputParams.inputType"
      :autosize="inputParams.autosize"
    /> -->
    <Select v-model="modelValue" :allow-create="inputParams.allowCreate" filterable
      :placeholder="inputParams.placeholder" :multiple="inputParams.multiple" @on-create="handleCreate1">
      <Option v-for="(item, index) in inputParams.options" :value="item.value" :key="index">
        {{ item.label }}
      </Option>
    </Select>
  </div>
</template>

<script>
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel"],
  components: {
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams, this.keyPath)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
  },
  methods: {
    handleCreate1(val) {
      // this.inputParams.options.push({
      //   value: val,
      //   label: val,
      // });
    },
  },
};
export const isStringType = true;
</script>
