<template>

  <div :class="{
    'grid-item': true,
    dense: dense,
    small: size === 'small',
    'grid-item-top': labelPosition === 'top',
    'grid-item-left': labelPosition !== 'top',
  }">
    <div class="grid-view-label" :style="{ flexBasis: labelWidth }">
      {{ inputParams.label }}
    </div>
    <component :is="inputComp" :inputParams="inputParams" class="grid-view-content">
      <slot></slot>
    </component>

  </div>
</template>
<script>
import inputs from "./inputs/input";
export default {
  inject: ["labelWidth", "labelPosition", "dense", "size"],
  props: {
    inputParams: Object,
    selected: Boolean,
    type: {
      type: String,
      default: "single",
      validator(value) {
        return ["single", "array"].indexOf(value) !== -1;
      },
    },
    index: Number,
    // labelPosition: {
    //   type: Boolean,
    //   default: true
    // },
    // labelWidth: {
    //   type: String,
    //   default: "80px"
    // },
    // dense: {
    //   type: Boolean,
    //   default: false
    // }
  },
  computed: {
    inputComp() {
      return inputs[this.inputParams.type];
    },
    isNotInput() {
      return !!inputs[this.inputParams.type].isNotInput;
    },
    itemProp() {
      if (this.type === "single") {
        return this.inputParams.index;
      } else if (this.type == "array") {
        return `items.${this.index}.${this.inputParams.id}`;
      } else {
        return null;
      }
    },

  },
  mounted() { },
};
</script>
<style lang="less" scoped>
@import './cardGrid.less';
</style>
