import Main from "@/views/Main.vue";
import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        //path: "/frame/list",
        path: "/",
        name: "frameList",
        component: () => import("@/views/FrameList.vue"),
      },
      {
        path: "/frame/:frameId",
        name: "frame",
        props: true,
        component: () => import("@/views/Frame.vue"),
      },
    ],
  },
  {
    path: "/single/:frameId/:scene",
    name: "singlePage",
    props: true,
    component: () => import("@/views/SinglePage.vue"),
  },
  {
    path: "/dev/:frameId",
    name: "dev",
    props: true,
    component: () => import("@/views/Dev.vue"),
    children: [
      {
        path: "export",
        name: "Export",
        component: () => import("@/components/dev/export/Export.vue"),
      },
      {
        path: "scheme",
        name: "Scheme",
        component: () => import("@/components/dev/scheme/SchemeControl.vue"),
      },
      {
        path: "plot",
        name: "Plot",
        component: () => import("@/components/dev/plot/PlotControl.vue"),
      },
      {
        path: "form",
        name: "Form",
        component: () => import("@/components/dev/form/FormControl.vue"),
      },
      {
        path: "grid",
        name: "Grid",
        component: () => import("@/components/dev/grid/GridControl.vue"),
      },
      {
        path: "table",
        name: "Table",
        component: () => import("@/components/dev/table/TableControl.vue"),
      },
      {
        path: "state",
        name: "State",
        component: () => import("@/components/dev/state/StateControl.vue"),
      },
      {
        path: "handlerList",
        name: "HandlerList",
        component: () => import("@/components/dev/handler/Handler.vue"),
      },
      {
        path: "scheduleList",
        name: "ScheduleList",
        component: () => import("@/components/dev/schedule/ScheduleList.vue"),
      },
    ],
  },
  {
    path: "/viewHandler/:frameId/:id",
    name: "Handler",
    props: true,
    component: () => import("@/views/Handler.vue"),
  },
  {
    path: "/viewSchedule/:frameId/:id",
    name: "Schedule",
    props: true,
    component: () => import("@/views/Schedule.vue"),
  },
  {
    path: "/adminCls",
    name: "admin",
    props: true,
    component: () => import("@/views/Admin.vue"),
    children: [
      {
        path: "user",
        name: "admin-user",
        component: () => import("@/components/admin/User.vue"),
      },
      {
        path: "framePolicy",
        name: "framePolicy",
        component: () => import("@/components/admin/FramePolicy.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/user/Login.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/user/Signin.vue"),
  },
  {
    path: "/invite/:id",
    name: "invite",
    props: true,
    component: () => import("@/views/user/Invite.vue"),
  },
  {
    path: "/baseInfo",
    name: "baseInfo",
    component: () => import("@/views/user/BaseInfo.vue"),
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});
const checkLogin = () => {
  return Cookies.get("token");
};
router.beforeEach(async (to, from) => {
  if (
    // 检查用户是否已登录
    to.name !== "login" &&
    to.name !== "signin" &&
    to.name !== "singlePage"
  ) {
    if (!checkLogin()) {
      // 将用户重定向到登录页面
      return { name: "login" };
    }
  }
});
export default router;
