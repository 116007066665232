<template>
  <div class="eo-table-wrap" v-if="scene">
    <div>
      <div class="ivu-table-wrapper table-list-wrap" v-if="tableModel" @contextmenu.prevent>
        <div class="ivu-table ivu-table-default ivu-table-small" :style="{ width: width }">
          <div class="ivu-table-body">
            <table class="eo-table" style="" cellspacing="0" cellpadding="0" :height="maxHeight">
              <colgroup>
                <template v-for="(col, index) in columnPreset">
                  <col v-for="n in col.span || 1" :key="index * 1000 + n" :style="col.style" :class="col.class" />
                </template>
              </colgroup>
              <thead>
                <tr>
                  <th v-for="(col, index) in columnPreset" :key="index" :colspan="col.span" :style="col.style"
                    :class="col.class">
                    <div class="ivu-table-cell cell-th">
                      <span>{{ col.text }}</span>
                      <span class="sort-btn" v-if="col.sort">
                        <!-- <i :class="{ bi: true, 'bi-caret-up': initCardParams?.sort?.[col.id] == 0 }"
                        @click="newSort(col, 1)"></i> -->
                        <i :class="{
                          bi: true, 'bi-arrow-down-up': initCardParams?.sort?.[col.id] == undefined,
                          'bi-arrow-up': initCardParams?.sort?.['tag.' + col.id] == 1,
                          'bi-arrow-down': initCardParams?.sort?.['tag.' + col.id] == -1
                        }" @click="newSort(col)"></i>
                        <!-- {{ col.id }}
                      {{ initCardParams?.sort }} -->
                        <!-- <i class="bi bi-caret-down" @click="newSort(col, -1)"></i> -->
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="ivu-table-tbody eo-table-tbody">
                <tr v-for="(row, rowIndex) in tableStruct.rows" v-show="!isCollapsed(rowIndex)" :key="rowIndex"
                  class="ivu-table-row">
                  <template v-for="(cell, colIndex) in row">
                    <td v-if="cell" :key="colIndex" :colspan="cell.colSpan || 1" :rowspan="cell.rowSpan || 1" :style="{
                      ...(cell.style || {}),
                    }" :class="{
                      'eo-table-cell-active': isActive(rowIndex, colIndex),
                      'eo-table-card-active': isActiveContext(cell.id),
                      ...(cell.class || {})
                    }" @click.left="activateCell(rowIndex, colIndex, cell)"
                      @click.right="activateCard(rowIndex, colIndex, cell)" @contextmenu.prevent
                      @dblclick="editCell(cell, rowIndex, colIndex)" @click.ctrl="ctrlClick(cell)">
                      <div class="ivu-table-cell">
                        <div v-if="cell.collapse &&
                          tableStruct.collapseRows.indexOf(rowIndex) !== -1 &&
                          colIndex === 0
                        " :class="{
                          'ivu-table-cell-expand': true,
                          'ivu-table-cell-expand-expanded': !collapseState[
                            rowIndex
                          ],
                        }" style="display: inline-block" @click.stop="toggleCollapse(rowIndex)" @dblclick.stop>
                          <Icon type="ios-arrow-forward" />
                        </div>

                        <template v-if="cell.property.type === 'text'">
                          <Input v-if="isEditing(rowIndex, colIndex) &&
                            typeof editingValue === 'string'
                          " class="table-input" type="textarea" ref="tableInput" autofocus rows="1"
                            v-model="editingValue" @on-blur="submitCell(cell)" @on-enter="submitCell(cell)"></Input>
                          <span v-else :style="cell.property.style" :class="cell.property?.class">
                            {{ cellValue(cell) }}
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'number'">
                          <InputNumber v-if="isEditing(rowIndex, colIndex)" class="table-input" v-model="editingValue"
                            @on-blur="submitCell(cell)" @on-enter="submitCell(cell)"></InputNumber>
                          <span v-else :style="cell.property.style" :class="cell.property?.class">
                            {{ numberFormat(cell) }}
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'plot'">
                          <span :style="cell.property.style" :class="cell.property?.class">
                            <PlotRender :params="{ _curNode: cell.selfNode, preNode: tablePreNode }"
                              :scene="cell?.property?.scene">
                            </PlotRender>
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'static'">
                          <span :style="cell.property.style" :class="cell.property?.class">
                            {{ cell.property.text }}
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'html'">
                          <span :style="cell.property.style" :class="cell.property?.class" class="html-wrap">
                            <div v-html="cellValue(cell)"></div>
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'time'">
                          <span :style="cell.property.style" :class="cell.property?.class">
                            {{ timeFormat(cell) }}
                          </span>
                        </template>

                        <template v-else-if="cell.property.type === 'raw'">
                          <span :style="cell.property.style" :class="cell.property?.class">
                            {{ cell.selfNode }}
                          </span>
                        </template>
                        <template v-else-if="cell.property.type === 'pics'">
                          <div class="pics-wrap">
                            <div v-for="(pic, index) in cell.selfNode.node.tag[
                              cell.property.id
                            ]" :key="index" class="pics" :style="[
                              {
                                backgroundImage: 'url(' + pic + ')',
                              },
                              cell.property.style,
                            ]" :class="cell.property?.class">
                              <!-- <img :src="pic" /> -->
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cell.property.type === 'list-text'">
                          <div :style="cell.property.style" :class="cell.property?.class">
                            <div v-for="(text, index) in jsonParse(cellValue(cell))" :key="index">
                              {{ text }}
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cell.property.type === 'pic'">
                          <!-- <span> -->
                          <!-- <img class="table-pic" v-if="cellValue(cell)" :src="cellValue(cell)"
                            :style="cell.property.style"> -->
                          <Image :src="cellValue(cell)" :fit="cell.property.fit" preview class="table-pic"
                            :class="[cell.property.imageSize,]" :style="cell.property.style"
                            :preview-list="[cellValue(cell)]" :initial-index="0">
                            <template #error>
                              <div></div>
                            </template>
                          </Image>
                          <!-- </span> -->
                        </template>

                      </div>
                    </td>
                    <td v-else-if="cell !== null" @click="activateCell(rowIndex, colIndex)"></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal v-model="showCardModal" :styles="modalStyle" footer-hide>
        <div :style="{ minHeight: '480px' }">
          <PlotRender v-if="curContext && cardViewScene && showCardModal"
            :params="{ _curNode: curContext, preNode: tablePreNode }" :scene="cardViewScene">
          </PlotRender>
        </div>

      </Modal>
    </div>
    <div class="d-flex" v-if="showBottom" style="display: flex; justify-content: start;margin-top: 1px;height: 28px;">
      <div style="flex:1 1 auto"></div>

      <div style="line-height: 26px;padding: 0 6px;font-size: 13px;color: rgb(138 142 153);">
        {{ tableStruct?.rows?.length }} /
      </div>
      <Page v-if="paging && paging.total > paging.perPage" :total="paging.total" size="small"
        :page-size="paging.perPage" show-total @on-change="fetchContext"
        style="line-height: 26px;font-size: 13px;color: rgb(138 142 153);margin-right: 6px;" />
      <!-- <Button @click="curPageMode = 'full'" class="re-small table-foot-btn" size="small" style="margin-right:2px">
        <i class="bi bi-list-ol"></i>
      </Button> -->
      <Button @click="fetchContext(1)" class="re-small table-foot-btn" size="small">

        <div class="icon-container">
          <i v-show="!loading" class="bi bi-arrow-clockwise"></i>
          <i v-show="loading" :class="{ bi: true, 'bi-arrow-clockwise': true, 'loading-icon': loading }"></i>
        </div>

      </Button>
    </div>
  </div>
</template>
<script>
import { useDatapoolStore } from '@/stores/datapool';
import { useNodeStore } from '@/stores/node';
import dayjs from 'dayjs';
import { eval as expEval, parse as expParse } from "expression-eval";
import _ from "lodash-es";
import cloneDeep from "lodash-es/cloneDeep";
import findIndex from "lodash-es/findIndex";
import isEqual from "lodash-es/isEqual";
import { ref } from "vue";
// expParse.plugins.register(jsepObject);

export default {
  name: "TableRender",
  props: {
    width: { type: String, default: "auto" },
    scene: String,
    branch: String,
    mountedWatch: { type: [String, Number, Array, Boolean], },
    // curNode: Boolean,
    filter: Object,
    query: Object,
    sort: Object,
    perPage: { type: Number, default: 20 },
    maxHeight: { type: String, default: "100%" },
    showHeader: { type: Boolean, default: true },
    showBottom: { type: Boolean, default: true },

    preNode: { type: Object },
    listHandler: String,
    modalStyle: {
      type: Object,
      default: function () {
        return {
          top: "40px",
          width: "1080px",
          // minWidth: "1080px",
          minHeight: "480px",
        };
      },
    },
    scroll: { type: String, default: "overlay" },
    pageMode: {
      type: String,
      default: "paging",//full,extend
      validator(value) {
        return ["paging", "full"].includes(value);
      },
    },
  },
  components: {
    // CardPlotRender: CardPlotRender,
    //  TablePlotRender: () => import("./TablePlotRender.js"),
  },
  data() {
    return {
      activeCell: {
        col: null,
        row: null,
        context: {},
      },
      activeContext: {
        id: undefined,
        position: undefined,
      },
      editingCell: {
        col: null,
        row: null,
      },
      showCardModal: false,
      editingValue: null,
      collapseState: {},
      curContext: undefined,
      curEdge: undefined,
      cardViewScene: undefined,
      dispatchedInitPositions: [],
      flags: [],
      paging: {
        // "total": 2,
        // "current": 0,
        // "perPage": 200
      },
      curPageNodes: [],
      curPageMode: "paging",
      loading: false,
      tablePreNode: undefined
    };
  },
  inject: {
    _curNode: {
      from: '_curNode',
      default: {}
    },
    curNode: {
      from: 'curNode',
      default: () => { }
    },
  },
  methods: {
    activateCell(row, col, cell) {
      this.activeCell.row = row;
      this.activeCell.col = col;
      this.activeContext.id = cell.selfNode?.vid;
      this.activeContext.position = cell.selfNode.position;

      this.curContext = ref(cell.selfNode);
      this.cardViewScene = cell.viewScene;
      if (cell.showCardModal) {
        this.showCardModal = true;
      }
      this.$emit("Select", [cell.selfNode])
    },
    activateCard(row, col, cell) {
      if (this.activeContext.id == cell.selfNode?.vid) {
        this.activeContext = {
          id: undefined,
          position: undefined,
        };

        this.activeCell = {
          col: null,
          row: null,
          context: {},
        };
        this.curContext = undefined;
        this.curEdge = undefined
        return;
      }
      this.activeCell.row = row;
      this.activeCell.col = col;
      // if (cell.selfNode.node !== undefined && cell.selfNode.node._id) {
      //   this.activeContext.id = cell.selfNode.node._id;
      // } else {
      //   this.activeContext.id = undefined;
      // }
      this.activeContext.id = cell?.selfNode?.vid;
      this.activeContext.position = cell.selfNode.position;

      this.curContext = cell.selfNode;

      // const curEdges = this.curContext?.edge.filter(e => {
      //   return e.src === this.preNode.vid
      // })

      // this.curEdges = curEdges

      this.cardViewScene = cell.viewScene;
      console.log('cardViewScene', cell)

      if (this.cardViewScene) {
        this.showCardModal = true;
      }
    },
    isActive(row, col) {
      return row == this.activeCell.row && col == this.activeCell.col;
    },
    isActiveContext(id) {
      return id != undefined && id === this.activeContext.id;
    },
    editCell(cell, row, col) {
      if (cell.input && cell.input.enable) {
        this.editingCell.row = row;
        this.editingCell.col = col;
        this.editingValue = this.cellValue(cell);
        // console.log('editingValue', cell, this.editingValue)
      }
    },
    ctrlClick(cell) {

      this.nodeStore.addTemporary(cell.selfNode)
      this.$Message.info('已插入暂存')
    },
    isEditing(row, col) {
      return row == this.editingCell.row && col == this.editingCell.col;
    },
    stopEditCell() {
      this.editingCell.row = null;
      this.editingCell.col = null;
    },
    cellContext(cell) {
      return cell.selfNode;
    },
    submitCell(cell) {
      const property = cell.property;
      const context = cell.selfNode;

      if (!context) {
        this.stopEditCell();
        return;
      }

      if (property && (property.type === "text" || property.type === "number") && context.node.tag) {
        context.node.tag[property.id] = this.editingValue;
      }

      this.$store
        .dispatch("arr_card/updateCard", { card: context, scene: "table" })
        .then((card) => { })
        .catch((err) => {
          //console.error(error)

          //  this.$Message.error(err.response.data.msg);
          this.$Message.error(err);
          // console.error(err);
        });

      this.stopEditCell();
    },
    toggleCollapse(row) {
      this.$set(this.collapseState, row, !this.collapseState[row]);
    },
    isCollapsed(row) {
      if (this.tableStruct.collapseRows.indexOf(row) !== -1) {
        return false;
      }
      return this.collapseState[this.tableStruct.collapseRowsRef[row]];
    },
    cellValue(cell) {
      const property = cell.property;
      const node = cell?.selfNode;
      //  console.log('node', node)
      if (node) {
        if (property.varietyType == 'tag') {
          return node?.[property.varietyType]?.[property.variety]?.[property.prop]

        } else if (property.varietyType == 'edge') {

          const curEdge = node?.edge.find(e => {
            return e.src === this.tablePreNode.vid && e.name === property.variety
          })
          console.log('curEdge', curEdge)

          return curEdge?.props?.[property.prop]
        }
      }
    },
    newSort(col) {

      console.log('newSort', col)
      const sortKey = 'tag.' + col.id
      let sort = this.initCardParams?.sort?.[sortKey]

      let sortNew = sort
      if (sort == 1) {
        sortNew = -1
      } else if (sort == -1) {
        sortNew = undefined
      } else if (sort == undefined) {
        sortNew = 1
      }
      if (this.initCardParams.sort) {
        this.initCardParams.sort[sortKey] = sortNew // {}

      } else {

        this.initCardParams.sort = { [sortKey]: sortNew }// {}

      }
      console.log(" this.initCardParams.sort", this.initCardParams.sort)
      this.fetchContext()
    },
    async fetchContext(current = 1, step = 0) {
      try {
        console.log('fetchContext')

        // if (!this.preId) {
        //   return;
        // }
        // console.log("this.preId", this.preId)
        this.loading = true;
        // let paramTxt = JSON.stringify(this.initCardParams);
        // paramTxt = paramTxt.replace(RegExp(_.escapeRegExp("^"), "g"), ".");
        // const initCardParams = JSON.parse(paramTxt);

        // let branch = this.branch
        // if (typeof this.branch == 'string') {
        //   branch = [branch]
        // }
        this.$emit("mounted", {
          paging: {
            perPage: step == 0 ? this.perPage : step,
            current: current - 1
          }
        });

        // if (this.listHandler) {
        //   //获取列表
        //   res = await axios.post(this.listHandler, {
        //     frameId: this.nodeStore.frame._id,
        //     position: {
        //       frameId: this.nodeStore.frame._id,
        //       coordinate: this.coordinate,
        //     },
        //     filter: initCardParams.filter,
        //     query: initCardParams.query,
        //     sort: initCardParams.sort,
        //     depth: initCardParams.depth,
        //     paging: {
        //       perPage: step == 0 ? this.perPage : step,
        //       current: current - 1
        //     }
        //   })

        //   // console.log('res', res)
        //   res.data.nodes.map(v => {
        //     console.log("hanlderInsert", v.records)

        //     this.nodeStore.initNode(v.records)
        //     if (v.branch == this.branch) {
        //       this.nodeStore.initBranch({ id: this.branch, list: v.records })
        //       this.curPageNodes = this.nodeStore.branch[this.branch];
        //       //  this.curPageNodes = v.records;

        //       this.paging = v.paging;
        //     } else if (v.index == 'table') {
        //       this.nodeStore.initBranch({ id: this.branch, list: v.records })

        //       this.curPageNodes = v.records;
        //       this.paging = v.paging;
        //     }
        //   })

        // } else {
        //   //通用接口
        //   res = await this.nodeStore.subNode({
        //     frameId: this.nodeStore.frame._id,
        //     // preIds: [this.nodeStore.frame._id],
        //     preIds: [this.preId],
        //     branchIds: { default: branch },
        //     // edgeTypeList: [""]
        //   })


        //   this.curPageNodes = res?.records ? res?.records : [];
        //   // this.curPageNodes = [];
        //   this.paging = res?.paging;
        // }

        if (!this.paging || this.paging.total <= this.paging.perPage) {
          this.curPageMode = "full";
        } else {
          this.curPageMode = "paging";
        }
      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;

      }
    },
    timeFormat(cell) {
      const property = cell.property;

      let value = this.cellValue(cell)
      let date = value
      if (typeof (value) !== 'string' && typeof (value) !== 'number' && typeof (value) !== 'undefined') {

        if (value?.hour) {

          date = new Date(
            value?.year,
            value?.month,  // 月份减去1，因为月份是从0开始的
            value?.day,
            value?.hour,
            value?.minute,
            value?.sec,
            Math.floor(value?.microsec / 1000) // 微秒转换为毫秒
          );
        } else {
          date = new Date(
            value?.year,
            value?.month,  // 月份减去1，因为月份是从0开始的
            value?.day,
          );
        }
      }
      let format = property.format ? property.format : "YYYY-MM-DD";
      // if (!this.inputParams?.format) {
      //   switch (this.inputParams?.innerType) {
      //     case "date": format = 'YYYY-MM-DD'; break
      //     case "time": format = 'hh:mm:ss'; break
      //     case "datetime": format = 'YYYY-MM-DD hh:mm:ss'; break
      //   }
      // } else {
      //   format = this.inputParams.format
      // }

      // console.log('timeFormat', date, property)
      if (date) {
        return dayjs(date).format(format);
      } else {
        return "";
      }
      //  const format = property.format ? property.format : "YYYY-M-D";
      //  return dayjs(time).format(format);
    },
    numberFormat(cell) {
      const property = cell.property;
      const node = cell?.selfNode;
      let value
      if (property.varietyType == 'tag') {
        value = node?.[property.varietyType]?.[property.variety]?.[property.prop]

      } else if (property.varietyType == 'edge') {

        const curEdge = node?.edge.find(e => {
          return e.src === this.tablePreNode.vid && e.name === property.variety
        })

        value = curEdge?.props?.[property.prop]
      }



      // const value = _.get(node.tag, property.id);
      if (value === undefined || value === null) {
        return "";
      }
      let float = property.float ? property.float : 0;
      float = float;
      return new Number(value)?.toFixed(float);
    },
    jsonParse(value) {
      try {
        return JSON.parse(value)
      } catch (err) {
        return ''
      }
    }
  },
  computed: {
    grids() {
      let grids = [];
      const vm = this;
      function evalExpression(expr, context) {
        try {
          if (typeof expr !== "string") {
            return expr;
          }

          let matches = /^\$\((.+)\)$/.exec(expr);
          if (!matches) {
            return expr;
          }
          expr = matches[1];

          return expEval(expParse(expr), context);
        } catch (err) {
          console.warn(`Eval expression ${expr} failed`, err);
          return "";
        }
      }

      function evalAction(action, context, grid) {
        switch (action.action) {
          case "renderRow":
            for (let index = 0; index < action.cols?.length; index++) {
              evalAction(
                {
                  action: "renderCell",
                  cell: action.cols[index],
                },
                context,
                grid
              );
            }
            break;

          case "renderCell":
            let cell = action.cell || {};

            let colSpan = evalExpression(cell.colSpan, context) || 1;
            let rowSpan = evalExpression(cell.rowSpan, context) || 1;
            let property = cell.property || {
              type: "text",
              text: "",
            };
            if (typeof property === "string") {
              property = { type: "expr", text: property };
            }

            grid.push({
              ...cell,
              property,
              colStart: context.$_cursorCol,
              colSpan,
              rowStart: context.$_cursorRow,
              rowSpan,
              text: evalExpression(property?.text, context),
              selfNode: context.$_text,
              viewScene: context.$_viewScene,
            });

            context.$_cursorCol += colSpan;
            break;

          case "nextRow":
            context.$_cursorRow += 1;
            context.$_cursorCol = 0;
            break;

          case "assignVariable":
            const res = evalExpression(action.expr, context);
            context[evalExpression(action.as, context)] = res;
            break;

          case "moveCursor":
            context.$_cursorRow += evalExpression(action.row, context) || 0;
            context.$_cursorCol += evalExpression(action.col, context) || 0;
            break;

          case "forEach":
            let arr = evalExpression(action.array, context);

            let indexAs = evalExpression(action.indexAs, context) || "$_index";
            let itemAs = evalExpression(action.itemAs) || "$_item";

            let settext = action.settext || false;
            if (!arr || !indexAs || !itemAs || !settext) {
              break;
            }

            let index = 0;

            let textBackup = context.$_text;

            for (const item of arr) {
              context[indexAs] = index;
              context[itemAs] = item;
              if (settext) {
                context.$_text = item;
              }

              evalActions(action.actions, context, grid);

              index++;
            }

            delete context[indexAs];
            delete context[itemAs];
            if (settext) {
              context.$_text = textBackup;
            }
            break;

          case "assignVariableScope":
            let as = evalExpression(action.as, context);
            console.debug(`=== Scope ${as} begin ===`);
            let origValue = context[as];

            context[as] = evalExpression(action.expr, context);
            evalActions(action.actions, context, grid);
            context[as] = origValue;
            console.debug(`=== Scope ${as} end ===`);
            break;

          case "setViewScene":
            context.$_viewScene = evalExpression(action.scene, context);
            break;

          case "viewSceneScope":
            evalAction(
              {
                action: "assignVariableScope",
                as: "$_viewScene",
                expr: action.scene,
                actions: action.actions,
              },
              context,
              grid
            );
            break;

          case "if":
            let result = evalExpression(action.cond, context);
            if (result) {
              if (action.then) {
                evalActions(action.then, context, grid);
              }
            } else {
              if (action.else) {
                evalActions(action.else, context, grid);
              }
            }
            break;
          default:
            throw new Error(`Invalid action: ${action.action}`);
        }
      }

      function evalActions(actions, context, grid) {
        for (const action of actions) {
          evalAction(action, context, grid);
        }
      }

      const contextFunctions = {
        getCardsByPosition(params) {
          const subCards = vm.nodeStore.getNodes(params)
          // 

          if (subCards?.length === 0) {
            // 如未曾加载，立即触发
            if (
              findIndex(vm.dispatchedInitPositions, (o) =>
                isEqual(o, params.position)
              ) === -1
            ) {
              // 获取卡片
              vm.nodeStore.initNodes(params)
              //  vm.$store.dispatch("arr_card/initCards", params);
              // 防止重入
              vm.dispatchedInitPositions.push(cloneDeep(params.position));
            }
          }
          return subCards;
        },
        getCardById(id) {
          const card = vm.nodeStore.getNodeById(id)
          // const card = vm.$store.getters["arr_card/getCardById"](id);
          return card;
        },
      };

      for (const inContext of this.contexts) {
        let grid = [];
        let context = {
          $_cursorRow: 0,
          $_cursorCol: 0,
          $_text: inContext,
          ...contextFunctions,
        };
        evalActions(this.tablePreset, context, grid);
        grid.context = inContext;
        grids.push(grid);
      }
      // console.log("grids", grids);
      return grids;
    },
    tableStruct() {
      if (!this.columnPreset) {
        return
      }
      let totalDisplayCols = 0;
      for (const col of this.columnPreset) {
        totalDisplayCols += col.span || 1;
      }

      let fullGrid = [];
      let collapseRows = [];
      let collapseRowsRef = [];

      for (let cardIndex = 0; cardIndex < this.grids?.length; cardIndex++) {
        const card = this.grids[cardIndex];
        let grid = [];

        for (let cellIndex = 0; cellIndex < card.length; cellIndex++) {
          const cell = card[cellIndex];
          const colEnd = cell.colStart + cell.colSpan - 1; // Horizontal
          const rowEnd = cell.rowStart + cell.rowSpan - 1; // Vertical
          if (grid.length < rowEnd + 1) {
            // Ensure enough rows
            grid.length = rowEnd + 1;
          }

          for (let rowIndex = cell.rowStart; rowIndex <= rowEnd; rowIndex++) {
            let row = grid[rowIndex];
            if (typeof row === "undefined") {
              // Init current row
              grid[rowIndex] = row = [];
              row.length = totalDisplayCols;
            }

            for (let colIndex = cell.colStart; colIndex <= colEnd; colIndex++) {
              if (typeof row[colIndex] !== "undefined") {
                console.warn(
                  `Overlapping cell [${cardIndex}, ${rowIndex}, ${colIndex}], cell #${cellIndex}`
                );
              }

              if (rowIndex === cell.rowStart && colIndex === cell.colStart) {
                // Display cell
                //if(cell)

                cell.rootId = card?.context?.vid;
                cell.rootPosition = card?.context?.position;

                if (cell.selfNode) {
                  cell.position = cell.selfNode?.position;
                  cell.id = cell.selfNode.vid;
                } else {
                  cell.position = card?.context?.position;
                  cell.id = card?.context?.vid;
                }

                row[colIndex] = cell;
              } else {
                // Remove cell
                row[colIndex] = null;
              }
            }
          }
        }

        for (let index = 0; index < grid?.length; index++) {
          collapseRowsRef.push(fullGrid?.length);
        }
        collapseRows.push(fullGrid?.length);
        fullGrid = fullGrid.concat(grid);
      }

      let table = {
        rows: fullGrid,
        collapseRows,
        collapseRowsRef,
      };

      // console.log("table", table);
      return table;
    },
    tableModel() {
      const tableModel = this.datapoolStore.getDatapoolByIndex('tables', this.scene, 'scene')
      // const tableModel = this.$store.getters["datapool/getTable"](this.scene);
      return tableModel;
    },
    tablePreset() {
      return this.tableModel?.def;
    },
    columnPreset() {
      return this.tableModel?.columnDef;
    },
    contexts() {
      //console.log("this.branch", this.nodeStore.getBranch(this.branch))
      const branch = this.nodeStore.getBranch({
        branchId: this.branch,
      })

      this.paging = branch.paging;
      const contexts = branch?.nodes
      if (!contexts) {
        return []
      }
      return contexts;
      // if (this.coordinate == null) {
      //   return [];
      // }
      // if (this.curPageMode === "full") {
      //   const contexts = this.nodeStore.getNodes({
      //     position: {
      //       frameId: this.nodeStore.frame._id,
      //       coordinate: this.coordinate,
      //     },
      //     ...this.initCardParams,
      //     paging: this.curPageMode === "paging" ? this.paging : {},
      //   })
      //   console.log("contexts", this.coordinate, contexts)
      //   return contexts;
      // } else {
      //   return this.curPageNodes;
      // }
    },
  },
  watch: {
    mountedWatch: {
      handler: function (newVal, oldVal) {

        // if (oldVal == undefined) {
        //   return
        // }
        if (Array.isArray(newVal)) {
          for (let i = 0; i < newVal.length; i++) {
            if (!_.isEqual(newVal[i], oldVal?.[i])) {
              this.fetchContext(1, 0);
              break
            }
          }
        } else {
          if (!_.isEqual(newVal, oldVal)) {
            this.fetchContext(1, 0);
          }
        }
        // this.fetchContext(1, 0);
      },
      deep: true,
      immediate: true,
    },
    preNode: {
      handler: function (newVal, oldVal) {
        if (this.preNode) {
          this.tablePreNode = this.preNode
        } else {
          this.tablePreNode = this._curNode
        }
      },
      immediate: true,
    }
  },
  created() {
    this.curPageMode = this.pageMode;
    // if (this.preNode) {
    //   this.tablePreNode = this.preNode
    // } else {
    //   this.tablePreNode = this._curNode
    // }
  },
  setup() {
    const nodeStore = useNodeStore()
    const datapoolStore = useDatapoolStore()
    return {
      nodeStore,
      datapoolStore
    }
  }
};
</script>

<style lang="less" scoped>
.eo-table-wrap {
  // border: 1px solid rgba(248, 248, 249, 60%);
}

.table-foot-btn {
  margin-bottom: 0px;
  margin-top: 2px;
  background-color: rgb(251, 251, 252);
  border-color: #dcdee24f;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.loading-icon {
  animation: span 2s infinite linear;
}

@keyframes span {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.table-list-wrap {
  width: 100%;
  overflow-x: overlay;
  overflow-y: overlay;
  position: relative;

  .ivu-input-number-input-wrap {
    height: 28px;
  }
}

.scroll-table-x .table-list-wrap {
  overflow-x: scroll;
}

.cell-th {
  display: flex;
  justify-content: space-between;
  line-height: 1.4;
  color: #17283e;
}

.sort-btn {
  letter-spacing: 2px;
  line-height: 15px;
  background: #ffffff71;
  padding: 0 5px;
  margin: 4px 0;
  border-radius: 3px;
  color: #0755bf;
}

.ivu-table {
  max-width: none;
  background: #ffffff3a;
}

.eo-table {
  width: 100%;
}

.eo-table-tbody td {
  background-color: inherit;
  height: 2.5em;
  // vertical-align: top;
  vertical-align: middle;
  background: rgb(255, 255, 255);
  // display: table-cell;
}

.eo-table-card-active {
  // background: rgba(202, 215, 221, 0.226) !important;
  background: rgba(170, 207, 255, 0.158) !important;
}

.eo-table-cell-active {
  // background: rgba(202, 215, 221, 0.226) !important;
  box-shadow: inset 0px 0px 0 1px #a2c1ebba !important;
  // border-color: red !important;
}

// .table-input input {
//   border: none;
//   height: auto;
//   // margin-left: -9px;
// }
.ivu-table-body {
  // overflow: scroll;
  // margin-bottom: -8px;
  // margin-right: -4px;
  // border-bottom: 1px solid #dcdee2;
}

.ivu-table-cell {
  font-weight: 400;
  padding-left: 6px;
  padding-right: 6px;

  >span {
    padding-top: 4px;
    padding-bottom: 4px;
    display: inline-block;
  }
}

.ivu-table-header {}

.center-text {
  text-align: center;
}

.pics-wrap {
  display: flex;
  gap: 1px;
}

.pics {
  width: 54px;
  height: 48px;
  flex: 0 0 54px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;

  img {
    width: 100%;
  }
}

.table-pic {
  height: 54px;
  object-fit: cover;
  width: 100%;
  margin: 5px 0 0;

}

.rect120 {
  width: 120px;
  height: 48px;
}

.sq48 {
  width: 48px;
  height: 48px;
}

.sq26 {
  width: 26px;
  height: 26px;
}

.rect240 {
  width: 240px;
  height: 96px;

}

.html-wrap {
  max-height: 56px;
  display: block !important;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
